<template>
  <div class="registerPageBox">
    <div class="contentBox">
      <h2 class="loginTitle">
        采金链系统注册
      </h2>
      <p class="loginDescription">
        整合上游供应链资源，构建互联网+传统产业
      </p>
      <div class="form">
        <div class="form-left">
          <img class="logo" :src="$localPicture+'logo/logo_white.png'" alt="">
          <div class="graphical" />
          <canvas id="canvas" class="canvas" />
        </div>
        <div class="form-right reigster-form">
          <!-- 这是注册的 -->
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="120px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item label="统一社会信用代码" prop="cmpUnicode">
            <el-input v-model="ruleForm.cmpUnicode" placeholder="请输入统一社会信用代码" />
          </el-form-item> -->
            <el-form-item class="shfit" label="账号名称" prop="userCode">
              <el-input v-model="ruleForm.userCode" placeholder="请输入账号名称" />
            </el-form-item>
            <el-form-item label="登录密码" prop="password1">
              <el-input v-model="ruleForm.password1" type="password" placeholder="请输入登录密码" />
            </el-form-item>
            <el-form-item label="密码确认" prop="checkPassword">
              <el-input v-model="ruleForm.checkPassword" type="password" placeholder="请再次输入登录密码" />
            </el-form-item>
            <el-form-item label="公司名称" prop="cmpName">
              <el-input v-model="ruleForm.cmpName" placeholder="请输入公司名称" />
            </el-form-item>
            <el-form-item label="手机号码" prop="phoneNo">
              <el-input v-model="ruleForm.phoneNo" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item label="所属身份" prop="cmpRole">
              <el-radio-group v-model="ruleForm.cmpRole">
                <el-radio label="03">
                  经销商
                </el-radio>
                <el-radio label="04">
                  代采商
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="验证码" prop="phoneKaptcha">
              <div class="flexBox">
                <el-input v-model="ruleForm.phoneKaptcha" class="verificationCode" placeholder="请输入验证码" />
                <span :class="['getCode', disabled ? 'disabledActive' : '']" @click="getCode">{{ codeTitle }}</span>
              </div>
            </el-form-item>
            <div class="agreement">
              <el-checkbox v-model="agreeProtocol">
                阅读并同意<router-link :to="{ path: '/agreement'}" target="_blank">
                  《平台服务协议》
                </router-link>
                和<router-link :to="{ path: '/agreement'}" target="_blank">
                  《隐私政策》
                </router-link>
              </el-checkbox>
            </div>
            <el-form-item class="register-btn" label-width="0">
              <el-button type="primary" class="determine" @click="submitForm('ruleForm')">
                确 定
              </el-button>
              <el-button class="reset" @click="resetForm('ruleForm')">
                重 置
              </el-button>
            </el-form-item>
            <div class="jump">
              <router-link class="backLogin" :to="{ path: '/login' }">
                有账号？<span>去登录</span>
              </router-link>
            </div>
          </el-form>
          <span class="circular-one" />
          <span class="circular-two" />
          <span class="circular-three" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { register, sendPhoneCodeRegister } from '@/http/agentApi/login'
import generateCanvas from '@/assets/js/canvas.js'
import { encrypt } from '@/unit/index'
import { rules, formPattern } from '@/unit/matchFormRegex'
export default {
  data() {
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
      if (!reg.test(value)) {
        callback(new Error('字母、数字和其他符号至少包含2种'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loginOrRegister: false,
      agreeProtocol: false,
      codeTitle: '获取验证码',
      ruleForm: {
        cmpUnicode: '',
        cmpName: '',
        password1: '',
        userCode: '',
        phoneNo: '',
        checkPassword: '',
        phoneKaptcha: '',
        cmpRole: ''
      },
      disabled: false,
      rules: {
        phoneKaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur' }],
        cmpUnicode: [...rules.unicode],
        cmpRole: [...rules.selectiveType],
        userCode: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { required: true, validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { ...formPattern.tel }
        ],
        password1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      generateCanvas.circleBottom()
    })
  },
  methods: {
    // 点击注册确认按钮
    submitForm(formName) {
      if (!this.agreeProtocol) {
        this.$message.error('请勾选协议框！')
        return
      }
      // if (this.ruleForm.code !== this.ruleForm.phoneKaptcha) {
      //   this.$message.error('验证码错误')
      //   return
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.cmpRoleList = [this.ruleForm.cmpRole]
          this.ruleForm.password = encrypt(this.ruleForm.password1)
          const param = JSON.parse(JSON.stringify(this.ruleForm))
          delete param.password1
          register(param, () => {
            this.$router.push({ path: '/login' })
            this.$message.success('成功！')
          })
        }
      })
    },
    // 点击获取验证码
    getCode() {
      if (this.disabled) return
      if (!this.ruleForm.phoneNo || this.ruleForm.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      sendPhoneCodeRegister(this.ruleForm.phoneNo, res => {
        this.disabled = true
        let num = 60
        this.ruleForm.code = res.msg
        this.codeTitle = `${num}s`
        var timer = setInterval(() => {
          if (num <= 1) {
            this.disabled = false
            clearInterval(timer)
            this.codeTitle = '获取验证码'
            return
          }
          num--
          this.codeTitle = `${num}s`
        }, 1000)
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss">
input:-webkit-autofill { box-shadow: 0 0 0px 1000px white inset;}
.registerPageBox {
  background: url("../../../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 1400px;
  overflow: hidden;
  .contentBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loginTitle{
      color: #fff;
      font-size: 42px;
      font-weight: 500;
    }
    .loginDescription{
      color: #fff;
      font-size: 32px;
      font-weight: 500;
      padding-bottom: 48px;
    }
  }
  .form {
    display: flex;
    width: 50vw;
    min-width: 900px;
    height: 54vh;
    min-height: 485px;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
   a {
        color: #0089FF;
        margin: 6px;
      }
    .form-left {
      width: 50%;
      background-color: #DD2629;
      border-radius: 20px 0 0 20px;
      position: relative;
      overflow: hidden;;
      .logo {
        width: 386px;
        height: 114px;
        object-fit: contain;
        position: absolute;
        left: 0;
        right: 0;
        top: 160px;
        margin: auto;
      }
      .graphical {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: url('../../../assets/image/04.png') no-repeat top center;
        transform: scale(1.1);
      }
      .canvas {
        position: absolute;
        bottom: -50px;
        left: 0;
      }
    }

    .form-right {
      width: 50%;
      padding: 40px 62px;
      box-sizing: border-box;
      position: relative;

      .login-title {
        display: inline-block;
        font-size: 24px;
        color: #D71618;
        position: relative;
        margin-bottom: 30px;
      }
      .shfit{
        padding-top: 24px;
      }

      .circular-one {
        display: inline-block;
        background: url('../../../assets/image/03.png') no-repeat top left;
        background-size: 100% 100%;
        width: 85px;
        height: 77px;
        position: absolute;
        left: 20px;
        top: 24px;
      }

      .circular-two {
        display: inline-block;
        background: url('../../../assets/image/01.png') no-repeat top left;
        background-size: 100% 100%;
        width: 122px;
        height: 108px;
        position: absolute;
        right: 0px;
        top: 0px;
      }

      .circular-three {
        display: inline-block;
        background: url('../../../assets/image/02.png') no-repeat top left;
         background-size: 100% 100%;
        width: 143px;
        height: 168px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .el-form-item {
        margin-bottom: 12px;
      }
      .el-form-item__label {
        font-size: $mainFontSize;
        float: none;
        text-align: left;
        display: block;
        color: $mainFontColor;
        font-weight: 500;
        font-family: PingFang SC;
      }
    }

    .reigster-form {
      padding: 20px 50px;
      .login-title {
        margin-bottom: 10px;
      }
      .el-form-item__content {
        line-height: 28px;
      }
      .el-form-item {
        .el-input__inner {
          height: 28px;
        }
      }
      .el-form-item__label {
        float: left;
        height: 32px;
      }
      .agreement {
        margin: 12px 0;
      }
      .register-btn {
        padding: 0 10%;
        .el-form-item__content {
          display: flex;
          justify-content: space-between;
        }
        .el-button {
          width: 48%;
          border-radius: 34px;
        }
        .el-form-item__content {
          text-align: center;
        }
        .determine {
          border-color: #D71618;
          background-color: #D71618;
        }
      }
    }

    .confirmLogin {
      text-align: center;
      margin-top: 50px;
      .el-button--primary {
        width: 80%;
        padding: 10px 0;
        background-color: #D71618;
        border-radius: 34px;
        border-color: #D71618;
      }
    }
    .el-input__inner {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(63, 63, 63, 0.3);
      color: #000000;
      font-size: $mainFontSize;
      &:hover {
        border-bottom: 1px solid rgba(63, 63, 63, 0.9);
      }
      ::placeholder {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .agreement {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 32px 0;
      .el-checkbox {
        color: $mainFontColor;
        .el-checkbox__label {
          font-size: 12px;
          color: rgb(85, 83, 83);
        }
      }
    }

    .jump {
      text-align: center;
      position: absolute;
      width: 80%;
      bottom: 20px;
      .backLogin {
        font-size: $mainFontSize;
        color: #999999;
        cursor: pointer;

        span {
          color: #D71618;
        }
      }
    }

    .verificationCode {
      display: inline-block;
      width: calc(100% - 120px);
    }
    .imageCode {
      display: inline-block;
      height: 40px;
      width: 100px;
      margin-left: 20px;
      cursor: pointer;
      vertical-align: bottom;
    }
    .flexBox{
      display: flex;
      z-index: 99;
    }
    .getCode {
      display: inline-block;
      height: 100%;
      width: 100px;
      margin-left: 20px;
      border: 0;
      outline: 0;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      background-color: #f8af00;
      z-index: 88;
    }
    .disabledActive {
      background-color: rgba(255, 255, 255, 0.3);
      color: #999;
    }
  }

  .el-checkbox__input {
    &.is-checked .el-checkbox__inner {
      border-color: #D71618;
      background-color: #D71618
    }
    &.is-focus .el-checkbox__inner {
      border-color: #D71618;
    }
    .el-checkbox__inner:hover {
      border-color: #D71618;
    }
  }
}

.kaptcha .el-form-item__label {
  margin-left: 12px;
}
</style>
